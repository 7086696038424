import React, { FC, Fragment, useEffect, useState } from "react";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createFirstTrip,
  createOneRoundTrip,
  createPayment,
  createReturnTrip,
  createTrip,
  getSeats
} from "api";
import { useQuery } from "react-query";
import { showApiErrorMessages } from "utils";
import { toast } from "react-toastify";
import PaymentDetailsModal from "shared/payment";
import { Bus } from "shared/bus";
import { ClassicBus } from "shared/classicBus";
import { ComfortBus } from "shared/ComfortBus";
import { PrimeBus } from "shared/primeBus";
import { BusinessBus } from "shared/businessBus";
import { FirstTenBus } from "shared/firstTen";
import { FirstEightBus } from "shared/firstEight";
import i18next from "i18next";
import { EconomyBus } from "shared/EconomyBus";
import { Draw_Bus } from "shared/Draw_Bus/Draw_Bus";
import Layout from "shared/FaceBook/Layout";
import { TazcraBus } from "shared/TazkaraBus";
import isSeatNumberExists from "utils/isNumbe4rexistsinarr";

export interface CheckOutPageProps {
  className?: string;
}

const CheckOutPage: FC<CheckOutPageProps> = ({ className = "" }) => {
  const { search } = useLocation();
  const [date, setDate] = useState<string>("");
  const [travelTo, setTravelTo] = useState<string>("");
  const [travelFrom, setTravelFrom] = useState<string>("");
  const [cityTo, setCityTo] = useState<string>("");
  const [cityFrom, setCityFrom] = useState<string>("");
  const { t } = useTranslation();
  const [seats, setSeats] = useState([]);
  const [id, setId] = useState("");
  const [priceData, setPriceData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<number | string>("");
  const [iframe, setIframe] = useState<null | string>(null);
  let [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState<string | null>("");
  const [selectedSeatsList, setSelectedSeatsList] = useState<any>({});
  const [seatsType, setSeatsType] = useState("");
  const [end_Date, setend_Date] = useState();
  const [flagbus, setFlagbus] = useState("any");
  const [salon_cols, setSalon_cols] = useState(0);
  const [salon_rows, setSalon_rows] = useState(0);

  const [company_name, setCompanyName] = useState(localStorage.getItem('current_company_name'));

  const [ID, setID] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const page_path_name = window.location.pathname +window.location.search
  window.localStorage.setItem("page_path_name" ,page_path_name)
  const dropOffLocationType = window.localStorage.getItem(
    "dropOffLocationType"
  );
  const first = window.localStorage.getItem("first");
  const tf: any = window.localStorage.getItem("travle_from_bus");
  const travle_from_bus = JSON.parse(tf);

  const tt: any = window.localStorage.getItem("travle_to_bus");
  const travle_to_bus = JSON.parse(tt);

  const isFirsftTripFinshed = window.localStorage.getItem(
    "isFirsftTripFinshed"
  );

  useEffect(() => {
    if (dropOffLocationType !== "oneWay") {
      const END: any = window.localStorage.getItem("busEndDate");
      const end_date = JSON.parse(END);
      setend_Date(end_date);
    }
    if (flagbus !== undefined) {
      const first: any = window.localStorage.getItem("bus_First_Ticket");
      const i = JSON.parse(first);
      setID(i?.id);
    }
  }, []);

  useEffect(() => {
    if (!!search) {
      const data = search?.slice(1)?.split("/");
      // console.log("search data ", data);
      setDate(data?.[0]);
      setTravelFrom(data?.[1]);
      setTravelTo(data?.[2]);
      setId(data?.[3]);
      setCityFrom(data?.[5]);
      setCityTo(data?.[6]);
      setType(data?.[7]);
      setSeatsType(data?.[8]);

      sessionStorage.setItem(
        "path",
        location?.pathname +
          `?${data?.[0]}/${data?.[1]}/${data?.[2]}/${data?.[3]}/${data?.[4]}/${data?.[5]}/${data?.[6]}/${data?.[7]}/${data?.[8]}`
      );
    }
  }, [search]);
  
  const { data } = useQuery(
    ["getSeats", travelFrom, travelTo, id ],
    () => {
      
      return getSeats({
        from_location_id: travelFrom,
        to_location_id: travelTo,
        id,
        date,
        cityFrom: cityFrom,
        cityTo: cityTo
      });
    },
    {
      keepPreviousData: true,
      onSuccess: (response) => {
        console.log('from response of seats',response.data.data );
        // we will get salon col
        if(company_name === 'Tazcara'){

          setSalon_cols(response.data.data.salon.columns)
          // we will get salon row
          setSalon_rows(response.data.data.salon.rows)
          setSeats(response.data.data.seats_map);
        }
        if (response?.data?.data?.length) {
          setSeats(response?.data?.data);
          console.log('seats from response',seats);
          response?.data?.data.forEach((item: any) => {
            if (item?.seat_type_name === "comfort") setType("");
          });
        }
      },
      onError: (errors: any) => {
        if (Object.keys(errors.response.data.errors)?.length) {
          showApiErrorMessages(errors.response.data.errors);
        } else {
          
          toast.error(errors.response.data.message);
        }
        if (errors.response.status === 401) {
          navigate("/login");
        }
      }
    }
  );


  const createFirsttrip = async () => {
    const seatsList: any = [];
    for (const property in selectedSeatsList) {
      seats.forEach((item: any) => {
        if (+item?.id === +selectedSeatsList[property]) {
          seatsList.push({
            seat_id: item?.id,
            seat_type_id: item?.seat_type_id
          });
        }
      });
    }
    setLoading(true);
    if (seatsList?.length) {
      const data = {
        round: 1,
        boarding: {
          trip_id: id,
          from_city_id: cityFrom,
          to_city_id: cityTo,
          from_location_id: travelFrom,
          to_location_id: travelTo,
          date: date,
          seats: seatsList
        }
      };
      await createFirstTrip(data)
        .then((res) => {
          setOrderId(res?.data?.data?.gateway_order_id);
          setPriceData(res?.data?.data);
          toast.success(t(res?.data?.message));
          let busTicket: any = JSON.stringify(res?.data?.data);
          window.localStorage.setItem("bus_First_Ticket", busTicket);
          // console.log("bus_First_Ticket", res?.data?.data);

          navigate(
            `/listing-bus?${end_Date}/
							${travle_from_bus?.id}/${travle_to_bus?.id}/${
              i18next.language === "en"
                ? travle_from_bus?.name_en
                : travle_from_bus?.name_ar
            }/${
              i18next.language === "en"
                ? travle_to_bus?.name_en
                : travle_to_bus?.name_ar
            }/first`
          );

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (Object.keys(err?.response?.data?.errors)?.length) {
            setLoading(false);
            showApiErrorMessages(err.response.data.errors);
          } else {
            setLoading(false);
            toast.error(err?.response?.data?.message);
          }
          if (err.response.status === 401) {
            navigate("/login");
          }
        });
    } else {
      toast.error(t("selectSeatPlz"));
      setLoading(false);
    }
  };

  const createReturnTicket = async () => {
    const seatsList: any = [];
    for (const property in selectedSeatsList) {
      seats.forEach((item: any) => {
        if (+item?.id === +selectedSeatsList[property]) {
          seatsList.push({
            seat_id: item?.id,
            seat_type_id: item?.seat_type_id
          });
        }
      });
    }

    setLoading(true);
    if (seatsList?.length) {
      const data = {
        trip_id: id,
        from_city_id: cityFrom,
        to_city_id: cityTo,
        from_location_id: travelFrom,
        to_location_id: travelTo,
        date: end_Date,
        seats: seatsList
      };

      await createReturnTrip(data, ID)
        .then((res) => {
          setOrderId(res?.data?.data?.gateway_order_id);
          setPriceData(res?.data?.data);
          toast.success(t(res?.data?.message));
          let busTicket: any = JSON.stringify(res?.data);
          window.localStorage.setItem("bus_Return_Ticket", busTicket);
          // console.log("create return  ticket", res?.data);
          navigate(`/bus-trip/twoRound/summary`);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (Object.keys(err?.response?.data?.errors)?.length) {
            setLoading(false);
            showApiErrorMessages(err.response.data.errors);
          } else {
            setLoading(false);
            toast.error(err?.response?.data?.message);
          }
          if (err.response.status === 401) {
            navigate("/login");
          }
        });
    } else {
      toast.error(t("selectSeatPlz"));
      setLoading(false);
    }
  };

  const createOneRound = async () => {
    console.log('create first trip',selectedSeatsList,seats);

    const seatsList: any = [];
    for (const property in selectedSeatsList) {
      seats.forEach((item: any) => {

        if (+item?.id === +selectedSeatsList[property] && !isSeatNumberExists(seatsList,item?.id)) {
          seatsList.push({
            seat_id: item?.id,
            seat_type_id: item?.seat_type_id
          });
          console.log('1');
        }
        else if( !isSeatNumberExists(seatsList,property.split("_")[1]) ){
          seatsList.push({
            seat_id:property.split("_")[1],
            seat_type_id: '1'
          });
          console.log('2');
      } 
      });
      console.log('tr',seatsList, isSeatNumberExists(seatsList,property.split("_")[1]) );
    }
    setLoading(true);
    if (seatsList?.length) {
      const data = {
        round: 1,
        boarding: {
          trip_id: id,
          from_city_id: cityFrom,
          to_city_id: cityTo,
          from_location_id: travelFrom,
          to_location_id: travelTo,
          date: date,
          seats: seatsList
        }
      };
      await createOneRoundTrip(data)
        .then((res) => {
          setOrderId(res?.data?.data?.gateway_order_id);
          setPriceData(res?.data?.data);
          toast.success(res?.data?.message);
          let busTicket: any = JSON.stringify(res?.data?.data);
          window.localStorage.setItem("bus_Ticket", busTicket);
          navigate(`/bus-trip/oneRound/summary`);
          setFlagbus("a");
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (Object.keys(err?.response?.data?.errors)?.length) {
            setLoading(false);
            showApiErrorMessages(err.response.data.errors);
          } else {
            setLoading(false);
            toast.error(err?.response?.data?.message);
          }
          if (err.response.status === 401) {
            navigate("/login");
          }
        });
    } else {
    toast.error(t("selectSeatPlz"));
      setLoading(false);
    }
  };

  const RenderButton = () => {
    if (dropOffLocationType === "oneWay") {
      return (
        <ButtonPrimary
          className="ml-3 bg-green-400 text-black hover:bg-green-600"
          loading={loading}
          onClick={() => createOneRound()}
        >
          {t("go to summary")}
        </ButtonPrimary>
      );
    } else {
      if (isFirsftTripFinshed === "first") {
        return (
          <ButtonPrimary
            className="ml-3 bg-green-400 text-black hover:bg-green-600"
            loading={loading}
            onClick={() => createReturnTicket()}
          >
            {t("go to summary")}
          </ButtonPrimary>
        );
      } else {
        return (
          <ButtonPrimary loading={loading} onClick={() => createFirsttrip()}>
            {t("book & go to 2ed trip")}
          </ButtonPrimary>
        );
      }
    }
  };

  const createPayments = async () => {
    if (!!orderId) {
      await createPayment(orderId)
        .then((res) => {
          if (res?.data?.data?.url) {
            setIframe(res?.data?.data?.url);
            setIsOpen(true);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (Object.keys(err?.response?.data?.errors)?.length) {
            setLoading(false);
            showApiErrorMessages(err.response.data.errors);
          } else {
            setLoading(false);
            toast.error(err?.response?.data?.message);
          }
        });
    } else {
      toast.error(t("notFound"));
      setLoading(false);
    }
  };

  const renderMain = () => {
    return (
      <div className="flex w-full items-start justify-start space-y-8 border-neutral-200 px-0 dark:border-neutral-700 sm:rounded-2xl sm:border sm:p-6 xl:p-8">
        <div className="w-full">
          {company_name === 'Tazcara' ? <>
          <TazcraBus seats={seats}
              selected={selectedSeatsList}
              setSelected={setSelectedSeatsList}
              rows={salon_rows}
              cols={salon_cols}
              />
          </> :<> 
          {type === "WEBUS" || seatsType.includes("Mini") || seatsType ==='V_i_p' ? (
            <Bus
              seats={seats}
              selected={selectedSeatsList}
              setSelected={setSelectedSeatsList}
            />
          ) : type === "Tazcara" ? (
            <ClassicBus
              seats={seats}
              selected={selectedSeatsList}
              setSelected={setSelectedSeatsList}
            />
          ) : seatsType === "Prime_Mix" ? (
            <PrimeBus
              seats={seats}
              selected={selectedSeatsList}
              setSelected={setSelectedSeatsList}
            />
          ) : seatsType === "Comfort" ? (
            <ComfortBus
              seats={seats}
              selected={selectedSeatsList}
              setSelected={setSelectedSeatsList}
            />
          ) : seatsType === "Business40" ? (
            <BusinessBus
              seats={seats}
              selected={selectedSeatsList}
              setSelected={setSelectedSeatsList}
            />
          ) : seatsType === "economy" || seatsType === "Economy" ? (
            <EconomyBus
              seats={seats}
              selected={selectedSeatsList}
              setSelected={setSelectedSeatsList}
            />
          ) : seatsType === "First10" ? (
            <FirstTenBus
              seats={seats}
              selected={selectedSeatsList}
              setSelected={setSelectedSeatsList}
            />
          ) : (
            <FirstEightBus
              seats={seats}
              selected={selectedSeatsList}
              setSelected={setSelectedSeatsList}
            />
          )}</>
        }
          {!!priceData && (
            <div className="my-3  w-full">
              
            </div>
          )}
        </div>
      </div>
    );
  };
  // if (!!iframe) return <></>;
  function handleBackClick() {
    window.history.back();
  }
  return (
    <Layout>
    <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
      <div className="flex h-[15vh] w-full flex-col bg-[#1d4179] ">
        {/* top nav */}
        <div className="relative mx-auto flex h-[50px] w-[80vw] flex-row  justify-between text-white ">
          <button
            className="flex w-[4vw] items-center justify-around"
            onClick={handleBackClick}
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 10 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.75 16.1538L1.25 8.65381L8.75 1.15381"
                stroke="white"
                
                strokeLinejoin="round"
              />
            </svg>
            <span className="mt-1">{t("back")}</span>
          </button>
        </div>
        {/* center data */}
        <div className="flex flex-col items-center justify-center pb-10 text-white">
          <span className="absolute z-0 h-[20vh] w-[20vh]   rotate-45 rounded bg-[#1d4179]"></span>
          <h3 className="z-10">
            {i18next.language === "en"
              ? travle_from_bus?.name_en
              : travle_from_bus?.name_ar}{" "}
            -{" "}
            {i18next.language === "en"
              ? travle_to_bus?.name_en
              : travle_to_bus?.name_ar}
          </h3>
          <h3 className="z-10">
            {date} . {seatsType}
          </h3>
        </div>
      </div>


      <main className=" flex h-full w-full flex-col  ">
        
        <div className="container mb-24 mt-11 flex w-full flex-col lg:mb-32 lg:flex-row max-2xl:mt-28 2xl:mt-28  mx-auto justify-center">
          <div className=" itmes-start flex justify-between   lg:w-3/5 lg:pr-10 xl:w-1/1">
            {renderMain()}
          </div>

          
        </div>

        <div className="container max-md:w-[80%] max-sm:translate-y-[-10px] sm:w-[80%] md:w-[80%] md:translate-y-[-70px]  lg:w-[95%] lg:translate-y-[-100px] xl:w-[95%] ">
          <div className="">{!orderId && RenderButton()}</div>
        </div>
      </main>

      <PaymentDetailsModal
        iframe={iframe}
        isOpenProp={isOpen}
        onCloseModal={() => setIsOpen(false)}
      />
      {/* <SeatsDetailsModal
        isOpenProp={true}
        onCloseModal={() => setIsOpen(false)}
      /> */}
    </div>
    </Layout>
  );
};

export default CheckOutPage;
