import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import convertNumToList from "utils/convertNumToList";

// Define the Seat interface
interface Seat {
  seat_no: null | number;
  class: string;
}

/**
 * Extracts data from a URL and returns it as a key-value object.
 * @param url - The URL string.
 * @returns {Record<string, string>} - The extracted data as a key-value object.
 */
function extractDataFromURL(url: string): Record<string, string> {
  const parts = url.split("/").filter(Boolean);
  const data: Record<string, string> = {};

  parts.forEach((part, index) => {
    const key = `key${index + 1}`;
    data[key] = part;
  });

  return data;
}

/**
 * TazcraBus component.
 * @param {Object} props - The component props.
 * @param {Function} props.setSelected - The callback function to set the selected seats.
 * @param {Seat[]} props.seats - The array of available seats.
 * @param {Seat[]} props.selected - The array of selected seats.
 * @param {string} props.salon - The salon data.
 * @param {number} props.cols - The number of columns in the salon.
 * @param {number} props.rows - The number of rows in the salon.
 * @returns {JSX.Element} - The TazcraBus component.
 */
export const TazcraBus = ({
  setSelected,
  seats,
  selected,
  salon,
  cols,
  rows,
}: any): JSX.Element => {
  const { t } = useTranslation();
  const [selectedList, setSelectedList] = useState<any>([]);
  const [company, setCompany] = useState<string>("");
  const avialable = "available";
  const booked = "booked";
  const space = "space";
  const door = "door";
  const toilet = "wc";
  const driver = "driver";
  const seatList = convertNumToList(49);

  /**
   * Sets the container when a seat is clicked.
   * If the clicked seat is available, it toggles the "selected" class and updates the selected seats.
   * If the clicked seat is already selected, it removes it from the selected seats.
   * @param {Event} e - The click event.
   */
  const setContainer = (e: any) => {
    if (e.target.classList.contains("bg-seat")) {
      const seatId = e.target.id;
  
      const seat: any = {};
      if (seatId) {
        const seatKey = `seat_${seatId}`;
  
        if (selectedList[seatKey] === undefined) {
          // Seat is not selected, add it to the selected seats
          seat[seatKey] = seatId;
          setSelectedList((prevSelectedList: any) => ({
            ...prevSelectedList,
            ...seat,
          }));
          setSelected((prevSelected: any) => ({ ...prevSelected, ...seat }));
          e.target.classList.add("selected-seat");
        } else {
          // Seat is already selected, remove it from the selected seats
          const { [seatKey]: _, ...seats } = selectedList;
          setSelectedList(seats);
          setSelected(seats);
          e.target.classList.remove("selected-seat");
        }
      }
    } else {
      console.log(
        "Cannot book",
        "First condition is: " + e.target.classList.contains("available")
      );
    }
  };

  /**
   * Sets the selected seats based on the seats array.
   * Updates the class of the seat elements accordingly.
   */
  const setSelectedSeats = () => {
    seatList.forEach((item) => {
      const ele: any = document.getElementById(item.toString());
      if (ele) {
        const filterSeats = seats.filter((seat: any) => seat?.id + "" === item.toString());
        if (filterSeats?.length === 0) {
          ele.classList.add("occupied");
        } else {
          ele.classList.remove("selected-seat");
        }
      }
    });
  };

  /**
   * Sets all seats as occupied.
   * Updates the class of the seat elements accordingly.
   */
  const setOccupiedSeats = () => {
    seatList.forEach((item) => {
      const ele: any = document.getElementById(item.toString());
      if (ele) {
        ele.classList.add("selected-bg");
      }
    });
  };

  // Extract the company name from the URL
  console.log("type bus", extractDataFromURL(window.location.href).key11);

  useEffect(() => {
    if (seats?.length > 0) {
      setSelectedSeats();
      setCompany(extractDataFromURL(window.location.href).key11);
    } else {
      setOccupiedSeats();
    }
  }, [JSON.stringify(seats)]);

  /**
   * Generates the salon layout for the TazcraBus component.
   * @returns {JSX.Element[]} - The array of seat elements.
   */
  const generateSalonLayout = (): JSX.Element[] => {
    const salonLayout: JSX.Element[] = [];

    for (let row = 0; row < rows; row++) {
      const rowSeats: JSX.Element[] = [];

      for (let col = 0; col < cols; col++) {
        const seatIndex = row * cols + col;
        const seat = seats[seatIndex];
        const seatNo = seat?.seat_no ? seat?.seat_no : null;

        // Define the class for the seat element
        let seatClass =
          seat?.class === avialable
            ? "bg-seat"
            : seat?.class === booked
            ? "bg-seat selected-bg"
            : seat?.class === driver
            ? "driver-bg"
            : seat?.class === toilet
            ? "toilet-bg"
            : seat?.class === space 
            ? "space-bg" 
            :  seat?.class === door ? 'door-bg':"";

        // Remove "occupied" class if seat is not in the selected list
        if (!selectedList[`seat_${seatIndex}`]) {
          seatClass = seatClass.replace("occupied", "");
        }

        // Create the seat element and add it to the rowSeats array
        rowSeats.push(
          <div
            key={seatIndex}
            className={`seat ${seatClass} flex items-end  justify-center text-[14px] font-[600] text-white hover:cursor-pointer `}
            id={seatNo?.toString()}
            onClick={(e: any) => setContainer(e)}
            title={seatNo?.toString()}
          >
            {seatNo}
          </div>
        );
      }

      // Create the row element and add it to the salonLayout array
      salonLayout.push(
        <div key={row} className="flex flex-row items-center justify-center rtl:flex-row-reverse">
          {rowSeats}
        </div>
      );
    }

    return salonLayout;
  };

  return (
    <div className="salon-container mt-5">
      <div className="salon-title  movie-container">
      <ul className="showcase ">
							<li>
								<div className="bg-seat"></div>
								<small>{t("availableSeat")}</small>
							</li>
							<li>
								<div className="bg-seat selected-seat"></div>
								<small>{t("ownSelect")}</small>
							</li>
							<li>
								<div className="bg-seat selected-bg"></div>
								<small>{t("notAvailable")}</small>
							</li>
						</ul>
      </div>
      <div className="salon-layout   ">{generateSalonLayout()}</div>
    </div>
  );
};