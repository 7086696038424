import React, { useEffect, useState } from "react";
import {
	Button,
	Dialog,
	DialogHeader,
	DialogBody,
	DialogFooter,
	Input,
	Textarea,
	Typography,
} from "@material-tailwind/react";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import { getTicket, createReview } from "api";
import { DialogCustomAnimation } from "./modalSuccess";

export function MessageDialog(ticket:string | any) {
	
	// console.log(ticket,"ana hna")
	const [open, setOpen] = React.useState(false);
	const [first_seen_open, setFirst_seen_open] = React.useState(true);
	const { t } = useTranslation();
	const [rating, setRating] = useState<any>("0");
	const [comment, setComment] = useState("");
	const [ticket_data, setTicketData] = useState<any>();
	const handleOpen = () => setOpen(!open);
	const handleOpenFirstSeen = () => setFirst_seen_open(!first_seen_open);
	const handleCommentChange = (event: any) => {
		setComment(event.target.value);
	};
	const handleButtonClick = () => {
		// Handle button click event here
		
			let data = new FormData();
			// data.append('rating','');
			data.append("comment", comment);

			createReview(data, ticket.ticket);
			toast.success("thank you.");
			handleOpenFirstSeen()
		
	};

	const handleRatingChange = (value: any) => {
		if (rating > 0) {
			toast.warning("you can not rate two times.");
		} else {
			setRating(value);
			let data = new FormData();
			data.append("rating", value);
			// data.append('comment', comment);
			createReview(data, ticket.ticket);
		}
	};
	useEffect(() => {
		const timer = setInterval(() => {
			// setCurrentTime(new Date());
		}, 1000);
		const fetchTicket = async () => {
			try {
				const ticket2 = await getTicket(ticket.ticket);
				// console.log(ticket2.data.data, "data from get ticket");
				setTicketData(ticket2.data.data);
				// console.log("done");
				if (ticket2.data.data.review) {
					if (ticket2.data.data.review.rating > 0) {
						setRating(ticket2.data.data.review.rating);
						handleOpenFirstSeen()
					}
					if (ticket2.data.data.review.comment) {
						setComment(ticket2.data.data.review.comment);
					}
				}
			} catch (error) {
				// Handle error
			}
		};
		fetchTicket();
		return () => {
			clearInterval(timer);
		};
	}, []);
	return (
		<>
		
		
			<Button onClick={handleOpen} className=" shadow-none m-0 p-0 relative">
                <div className=" w-[120px] h-10 p-4 bg-white rounded-[9px] shadow border border-blue-900 justify-center items-center gap-2 inline-flex">
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
                        className="mt-1 "
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M5.50002 7.00003H16.5C16.7764 7.00003 17 6.77639 17 6.50002C17 6.22364 16.7764 6 16.5 6H5.50002C5.22364 6 5 6.22364 5 6.50002C5.00005 6.77644 5.22369 7.00003 5.50002 7.00003Z"
							fill="#2E74BD"
						/>
						<path
							d="M11.5 12H5.50002C5.22364 12 5 12.2236 5 12.5C5 12.7764 5.22364 13 5.50002 13H11.5C11.7764 13 12 12.7764 12 12.5C12 12.2236 11.7764 12 11.5 12Z"
							fill="#2E74BD"
						/>
						<path
							d="M22.086 3.00002C22.057 3.00002 22.0289 3.00728 22 3.00864V3.00002C22 1.897 21.1026 1 20 1H2.00002C0.897469 1 0 1.897 0 3.00002V22.5C0 22.7022 0.122063 22.8848 0.308578 22.9619C0.370125 22.9878 0.435516 23 0.499969 23C0.629859 23 0.757781 22.9493 0.8535 22.8535L5.70703 18H20C21.1026 18 22 17.1031 22 16V7.70678L23.4395 6.26758C23.4395 6.26758 23.4395 6.26758 23.4395 6.26711C23.7959 5.91067 24 5.4175 24 4.91406C24 3.85844 23.1416 3.00002 22.086 3.00002ZM21 16C21 16.5513 20.5517 17 20 17H5.49998C5.36719 17 5.2402 17.0527 5.14645 17.1465L0.999984 21.293V3.00002C0.999984 2.44877 1.44825 2.00003 1.99997 2.00003H20C20.5518 2.00003 21 2.44877 21 3.00002V3.34155C20.9051 3.4068 20.8143 3.47856 20.7324 3.56055L15.2921 9.00002H5.49998C5.22361 9.00002 4.99997 9.22366 4.99997 9.50003C4.99997 9.77641 5.22361 10 5.49998 10H14.4899L14.0098 12.4019C13.9766 12.566 14.0283 12.7354 14.1465 12.8536C14.2412 12.9483 14.3692 13 14.5 13C14.5323 13 14.5655 12.9966 14.5977 12.9903L17.0967 12.4903C17.1944 12.4707 17.2832 12.4234 17.3526 12.3535L21 8.70667V16ZM16.7529 11.5396L15.1377 11.8628L15.461 10.2456L19.9998 5.70681L21.2929 6.99995L16.7529 11.5396ZM22.7324 5.56056L22 6.29298L20.7067 4.99975L21.4394 4.26709C21.6093 4.09764 21.8447 4 22.0859 4C22.5898 4 23 4.41016 23 4.91406C23 5.1543 22.9023 5.39017 22.7324 5.56056Z"
							fill="#2E74BD"
						/>
					</svg>
				<div className="text-blue-900 text-xs font-medium font-['Cairo'] leading-[30px]  ">Feedback</div>
                </div>
			</Button>
			<Dialog
				open={open}
				size="md"
				className="m-auto h-[80vh] max-h-[530px] max-sm:max-h-[85vh] !important] w-[80vw] max-w-[572px] px-6 pt-6 "
				handler={handleOpen}
			>
				
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					fill="currentColor"
					className="mr-3 h-5 w-5"
					onClick={handleOpen}
				>
					<path
						fillRule="evenodd"
						d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
						clipRule="evenodd"
					/>
				</svg>
				{first_seen_open ?<>
				<div className="flex flex-col items-center justify-between">
					<svg
						width="252"
						height="149"
						viewBox="0 0 252 149"
						className="m-auto h-[150px] w-[200px]"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M53.7344 111.969C57.5902 124.014 60.482 136.474 63.8072 148.678"
							stroke="#003458"
							stroke-width="2"
							strokeLinejoin="round"
						/>
						<path
							d="M62.4023 115.736C65.9398 125.862 68.5928 136.28 71.7942 146.529"
							stroke="#003458"
							stroke-width="2"
							strokeLinejoin="round"
						/>
						<path
							d="M70.2468 57.6779C77.6931 55.5289 85.8999 54.1848 93.5673 52.9909C94.655 52.8229 99.8904 51.2222 100.642 51.8766C102.614 53.5127 103.162 59.9596 103.64 62.3828C105.471 71.7392 108.84 82.9439 108.124 92.6099L104.701 92.9283C101.164 93.397 97.5822 93.8126 94.036 94.467C89.2095 95.3133 84.3417 95.9037 79.4529 96.2357C78.8604 96.2357 75.1992 96.7929 74.9604 96.4038C72.9264 92.9902 73.0591 86.1276 72.44 82.3249C70.1938 68.4847 67.3108 52.7786 75.27 40.2385C79.2496 33.9684 86.6958 28.5031 94.5577 31.0855C100.748 33.1018 104.498 40.6187 106.797 46.1813C111.44 57.4215 117.33 85.9772 116.056 98.6766C116.056 98.9684 108.336 99.6759 107.655 99.782C97.9802 101.179 88.1992 100.949 78.4713 102.656C63.906 105.212 49.491 108.555 34.9169 111.013C29.3543 111.951 21.4659 114.896 15.8944 112.782C9.14682 110.262 7.24542 100.834 5.41481 94.7147C1.41753 81.2725 -3.6852 50.0726 9.43861 39.0712C13.2325 35.8964 19.5733 35.5868 24.2339 34.7555C36.6679 32.5447 49.1815 31.3861 61.7393 30.2452C65.7189 29.8915 78.374 30.9351 80.1251 26.4337C84.8917 14.2562 63.6142 -5.62413 51.587 3.18404C48.7836 5.22689 49.2522 14.6187 52.3298 16.5643C54.6114 18.0058 59.0774 17.2276 61.6509 16.9799C65.7366 16.5731 69.8046 15.4323 73.8992 15.2112"
							stroke="#003458"
							stroke-width="2"
							strokeLinejoin="round"
						/>
						<path
							d="M97.3791 56.3242C93.0546 62.5147 88.5355 68.7052 84.4498 75.0549C83.9369 75.8508 81.275 80.8563 80.3994 80.8297C77.6403 80.7502 74.5097 78.7161 72.1484 77.3453"
							stroke="#003458"
							stroke-width="2"
							strokeLinejoin="round"
						/>
						<path
							d="M166.72 68.5726C161.202 71.0753 155.701 72.11 151.439 76.5848C150.705 77.363 149.245 79.5386 150.864 80.8298C152.632 82.2624 154.79 80.1576 155.409 78.7691C157.054 75.1433 149.918 68.1569 145.08 62.7358C140.693 57.7913 136.614 52.5815 132.867 47.1359C130.417 43.6957 127.641 39.5039 127.641 39.5039C127.641 39.5039 144.143 43.5718 151.518 45.8181C159.566 48.2589 168.268 48.8249 176.51 50.5759C178.358 50.9739 190.368 53.6358 191.005 54.4937C192.322 56.2624 191.084 61.9134 190.12 63.6733"
							stroke="#003458"
							stroke-width="2"
							strokeLinejoin="round"
						/>
						<path
							d="M127.641 44.1641C125.306 56.3681 123.599 63.505 122.22 68.5723C120.451 75.1873 117.329 84.4465 118.09 86.2594C118.638 87.5417 124.457 89.2573 125.616 89.7083C131.382 91.9457 137.555 93.3784 143.533 94.9349C151.89 97.1104 160.185 99.4097 168.578 101.426L178.447 103.849C178.447 103.849 184.31 86.1621 186.141 79.839"
							stroke="#003458"
							stroke-width="2"
							strokeLinejoin="round"
						/>
						<path
							d="M202.946 87.2326C200.346 86.4544 197.48 86.3483 194.986 85.2606C193.504 84.5051 192.122 83.5662 190.874 82.466C188.625 80.8947 186.499 79.1533 184.516 77.2571C184.109 76.8415 183.729 76.3728 183.339 75.966C182.95 75.5592 182.729 75.2674 182.455 74.8871C181.659 73.817 179.581 69.4129 182.605 69.3863C185.02 69.3863 184.454 73.2333 183.773 74.5156C183.243 75.4432 182.579 76.2866 181.801 77.0183C179.307 79.4945 173.072 83.9516 169.791 79.9809C168.19 78.053 168.597 75.1965 169.473 73.0476C171.042 69.4744 173.625 66.4397 176.901 64.319C177.973 63.436 179.208 62.772 180.536 62.3646C182.809 61.949 184.958 63.4877 186.85 64.4605C191.184 66.636 195.694 69.0238 199.311 72.3136"
							stroke="#003458"
							stroke-width="2"
							strokeLinejoin="round"
						/>
						<path
							d="M192.352 59.7656C195.597 61.3663 199.25 63.4003 201.796 65.9561C203.657 68.0212 205.255 70.3085 206.554 72.7657C207.892 74.7854 208.882 77.0155 209.481 79.3629C209.853 81.2731 210.667 85.2616 209.711 87.2249C209.596 87.4548 208.429 86.6677 208.332 86.5882C207.598 85.9779 205.626 83.2364 206.775 82.2459C207.412 81.6976 208.429 82.0249 209.119 82.2459C211.16 82.9249 213.125 83.8141 214.982 84.899C223.773 89.6568 232.174 94.9188 241.23 99.1902C244.272 100.623 247.668 103.011 251.002 103.612"
							stroke="#003458"
							stroke-width="2"
							strokeLinejoin="round"
						/>
						<path
							d="M180.039 99.4115C186.008 99.4115 192.42 101.454 198.381 100.367C199.698 100.119 203.359 99.2434 203.333 97.3509C203.333 96.272 202.059 95.8298 201.175 95.7414C200.357 95.6374 199.526 95.7705 198.782 96.1248C198.037 96.479 197.41 97.0397 196.974 97.7401C194.481 102.622 200.874 106.752 203.696 109.511C205.951 111.713 207.967 114.145 210.24 116.329C212.742 118.743 215.493 120.892 218.031 123.28C219.853 125.049 221.498 126.977 223.47 128.533C226.008 130.523 228.979 131.823 231.606 133.68"
							stroke="#003458"
							stroke-width="2"
							strokeLinejoin="round"
						/>
					</svg>
					<div className="w-[524px] text-center font-['HelveticaNeue'] text-2xl font-normal leading-[28.80px] text-stone-900 max-sm:w-[70vw]">
						Your Feedback Matters!
					</div>
				</div>
				<DialogBody>
				
					<div className="mx-[2.4rem] flex flex-col justify-between align-middle content-center text-[1rem] text-[#1D4179] max-sm:w-[120%] m-auto max-sm:translate-x-[-20%]">
						<div className="flex flex-col items-center">
							<div className="w-[524px] text-center font-['Cairo'] text-base font-normal leading-normal text-neutral-500 max-sm:w-[70vw]">
								We hope you had a wonderful experience with Safaria. Your
								feedback is invaluable to us. Please take a moment to share your
								thoughts and help us improve our services. Thank you!
							</div>
							{/* Rating stars */}
							<div className="flex items-center">
								{[1, 2, 3, 4, 5].map(value => (
									<span
										key={value}
										className={`${
											value <= rating ? "text-yellow-500" : "text-gray-400"
										} cursor-pointer text-2xl`}
										onClick={() => handleRatingChange(value)}
									>
										<svg
											width="24"
											height="25"
											className={`${
												value <= rating ? "fill-blue-300" : "fill-white"
											} cursor-pointer text-2xl`}
											viewBox="0 0 24 25"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M13.7289 4.1886L15.4889 7.7086C15.7289 8.1986 16.3689 8.6686 16.9089 8.7586L20.0989 9.2886C22.1389 9.6286 22.6189 11.1086 21.1489 12.5686L18.6689 15.0486C18.2489 15.4686 18.0189 16.2786 18.1489 16.8586L18.8589 19.9286C19.4189 22.3586 18.1289 23.2986 15.9789 22.0286L12.9889 20.2586C12.4489 19.9386 11.5589 19.9386 11.0089 20.2586L8.01893 22.0286C5.87893 23.2986 4.57893 22.3486 5.13893 19.9286L5.84893 16.8586C5.97893 16.2786 5.74893 15.4686 5.32893 15.0486L2.84893 12.5686C1.38893 11.1086 1.85893 9.6286 3.89893 9.2886L7.08893 8.7586C7.61893 8.6686 8.25893 8.1986 8.49893 7.7086L10.2589 4.1886C11.2189 2.2786 12.7789 2.2786 13.7289 4.1886Z"
												stroke="#003458"
												
												strokeLinejoin="round"
											/>
										</svg>
									</span>
								))}
							</div>
						</div>
						{/* Comment field */}
						<div className="mt-[8px] max-sm:w-[100%] ">
							<textarea
								id="comment"
								className="h-24 w-full rounded-2xl max-sm:h-[25vh]  max-sm:mx-0 border border-zinc-200 p-2 shadow first-letter:uppercase hover:border-zinc-200 focus:border-zinc-200"
								value={comment}
								placeholder="add feedback"
								onChange={e => handleCommentChange(e)}
								// readOnly={comment !== ''}
							></textarea>
						</div>
						{/* Button */}
						{/* <button
							className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
							onClick={handleButtonClick}
						>
							{t("send")}
						</button> */}
					</div>
				</DialogBody>
					</> :
					<div className=" flex flex-col justify-center align-middle">
					<svg width="150" height="150" viewBox="0 0 150 150" className="m-auto" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="149" height="149" rx="74.5" fill="#2E74BD"/>
<rect x="0.5" y="0.5" width="149" height="149" rx="74.5" stroke="#69696A"/>
<path d="M114.439 44.9343C116.196 46.6924 117.184 49.0765 117.184 51.5625C117.184 54.0484 116.196 56.4325 114.439 58.1906L67.5637 105.066C65.8056 106.823 63.4215 107.81 60.9356 107.81C58.4497 107.81 56.0655 106.823 54.3075 105.066L35.5575 86.3156C33.8497 84.5474 32.9048 82.1793 32.9261 79.7212C32.9475 77.2631 33.9335 74.9117 35.6717 73.1735C37.4099 71.4353 39.7612 70.4494 42.2193 70.428C44.6774 70.4066 47.0456 71.3516 48.8137 73.0593L60.9356 85.1812L101.182 44.9343C102.941 43.1768 105.325 42.1895 107.811 42.1895C110.296 42.1895 112.681 43.1768 114.439 44.9343Z" fill="white"/>
</svg>
<div className="w-[524px] text-center text-stone-900 text-2xl font-bold font-['Cairo']  max-sm:w-[70vw]">Feedback Successfully Received!</div>
<div className="w-[524px] text-center text-neutral-500 text-base font-normal font-['Cairo'] leading-normal max-sm:w-[70vw]">Thank you for sharing your feedback with us! Your input helps us continually enhance your experience with Safaria. We appreciate your valuable contribution.</div>
					</div>
					}
				<DialogFooter className="space-x-2 text-stone-800 absolute bottom-0 ltr:right-2 rtl:left-2">
				{first_seen_open?<Button variant="text" color="gray" onClick={handleOpen}>
						cancel
					</Button> :<></>}
					

					
						{first_seen_open? <Button
						variant="gradient"
						color="gray"
						className=" bg-[#2E74BD]"
						onClick={handleButtonClick}
					>
						Send feedback
					</Button> : <Button
						variant="gradient"
						color="gray"
						className=" bg-[#2E74BD]"
						onClick={handleOpen}
					>
						ok sound greeat
					</Button>}
				</DialogFooter>
			</Dialog>
		
			
		</>
	);
}
function setCurrentTime(arg0: Date) {
	throw new Error("Function not implemented.");
}

