import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
	filterTitle: string;
	filterType: string | null;
	isClosedIcon: JSX.Element;
	isOpenedIcon: JSX.Element;
	companyNamesDisplayed: string[];
	companyNames: string[];
	is_mobile: boolean;
	handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckBoxComponentFilter: React.FC<Props> = ({
	filterTitle,
	filterType,
	isClosedIcon,
	isOpenedIcon,
	companyNamesDisplayed,
	companyNames,
	is_mobile,
	handleCheckboxChange,
}) => {
	const [isCompaniesOpen, setIsCompaniesOpen] = useState(!is_mobile);

	const toggleCompaniesOpen = () => {
		setIsCompaniesOpen(!isCompaniesOpen);
	};
	const { t } = useTranslation();

	const handleClose = () => {
		setIsCompaniesOpen(false);
	};

	return (
		<div className="filter-section  rounded-lg bg-white p-[0.625rem]">
			<div
				className={`filter-header flex cursor-pointer items-center justify-between  py-2 px-4 ${
					is_mobile &&
					"rounded-lg border-[0.1rem]  border-gray-300 rtl:justify-between rtl:gap-3 rtl:p-2"
				} `}
				onClick={toggleCompaniesOpen}
			>
				<span
					className={`filter-title text-[1.05rem] text-[#1E1E1E] first-letter:uppercase ${
						filterType === "big" ? "w-[150px]" : "w-fit"
					} `}
				>
					{filterTitle}
				</span>
				<span className="filter-icon h-fit w-fit  text-[#DDE2EB]">
					{!isCompaniesOpen ? isClosedIcon : isOpenedIcon}
				</span>
			</div>
			{isCompaniesOpen && (
				<div
					className={`${
						is_mobile
							? "mx-10px z-20 rounded  bg-white pb-8  shadow-xl max-sm:absolute max-sm:left-2 max-sm:right-2 max-sm:mt-8 sm:absolute sm:mt-8 sm:w-[50%] "
							: ""
					}`}
				>
					<div className="flex justify-end pr-4 max-2xl:hidden max-xl:hidden max-lg:hidden lg:hidden  max-sm:block">
						<button
							className="text-gray-500 hover:text-gray-700 w-[50px] h-[50px] "
							onClick={handleClose}
						>
							<svg
								width="40"
								height="40"
								viewBox="0 0 56 56"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect width="56" height="56" rx="28" fill="#F7F8FE" />
								<path
									d="M29.4601 28.0003L33.5422 23.9183C33.7359 23.7249 33.8449 23.4625 33.8451 23.1887C33.8454 22.915 33.7369 22.6524 33.5435 22.4586C33.3501 22.2649 33.0876 22.1559 32.8139 22.1557C32.5402 22.1555 32.2776 22.264 32.0838 22.4574L28.0018 26.5394L23.9198 22.4574C23.726 22.2636 23.4633 22.1548 23.1893 22.1548C22.9153 22.1548 22.6526 22.2636 22.4588 22.4574C22.2651 22.6511 22.1562 22.9138 22.1562 23.1878C22.1562 23.4618 22.2651 23.7246 22.4588 23.9183L26.5409 28.0003L22.4588 32.0824C22.2651 32.2761 22.1562 32.5388 22.1562 32.8128C22.1562 33.0868 22.2651 33.3496 22.4588 33.5433C22.6526 33.737 22.9153 33.8459 23.1893 33.8459C23.4633 33.8459 23.726 33.737 23.9198 33.5433L28.0018 29.4613L32.0838 33.5433C32.2776 33.737 32.5403 33.8459 32.8143 33.8459C33.0883 33.8459 33.351 33.737 33.5448 33.5433C33.7385 33.3496 33.8473 33.0868 33.8473 32.8128C33.8473 32.5388 33.7385 32.2761 33.5448 32.0824L29.4601 28.0003Z"
									fill="#12161C"
								/>
							</svg>
						</button>
					</div>
					{companyNamesDisplayed.map((company: string) => (
						<div
							key={company}
							className="animate-filter-body m-2 flex items-center gap-[1rem] text-[#1E1E1E] duration-[1s]"
						>
							<input
								id={company}
								type="checkbox"
								value={company}
								className={`
                  ${companyNames.includes(company) && "checked"}
                  h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:ring-offset-gray-800 dark:focus:ring-blue-600 `}
								onChange={handleCheckboxChange}
								style={{
									width: "1.5rem",
									height: "1.5rem",
									padding: "0.31rem",
									borderRadius: "0.25rem",
									border: `0.125rem solid var(--Primary-color, #1D4179)`,
									color: "black",
									background: companyNames.includes(company) ? "#fff" : "#fff",
								}}
							/>
							<label
								htmlFor={company}
								className="text-[1.05rem] font-medium  text-[#69696A] ms-2 first-letter:uppercase dark:text-gray-300"
							>
								{company}
								{/* {t(company)} */}
							</label>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default CheckBoxComponentFilter;
